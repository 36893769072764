import * as React from "react";
import { ABOUT_ME } from "../../constants/why";
import "./styles/WhyContent.scss";

const WhyContent = () => {
  return (
    <div className="main-content" id="why-content">
      <section className="main-section">
        <div className="content-heading">
          <h2>Meet Dana</h2>
          <a href="https://www-danaforcambridge-com.translate.goog/?_x_tr_sl=en&_x_tr_tl=es&_x_tr_hl=en-US&_x_tr_pto=nui">
            <h5 style={{ marginLeft: "5px" }}>Hablo español</h5>
          </a>
        </div>
        <article>
          <div className="content-font article-padding">{ABOUT_ME.bio}</div>
        </article>
      </section>
    </div>
  );
};

export default WhyContent;
