import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const ABOUT_ME = {
  bio: (
    <div>
      <p>
        I am a longtime Cambridge renter, a multinationally award-winning data scientist, an econ/policy wonk, and an avid member of the local innovation community. A Boston area native, I graduated from Wellesley College where I majored in computer science. There I researched the influence of misinformation in political discourse using unique coding methods. I then studied data, economics, and development policy at MIT, worked as a data scientist, started a small software company, and initiated research at Northeastern University on using data from collective community insights to better inform local policy.
      </p>
      <div className="center">
        <StaticImage
          src="../images/meet_dana_2.png"
          alt="Collage"
          placeholder="blurred"
          className="meetDana"
        />
      </div>
      <p className="mt-50">
        I’m a passionate advocate of empirically effective policy on reducing economic inequality, addressing climate change, and improving criminal justice at the local, state, and national levels. Locally I help advocate on issues of youth services, public safety, climate, and education in Cambridge.
      </p>
      <p>
        As an East Cambridge renter who does not own a car, she relies almost solely on public transit, biking, and walking. And as a young woman in the technology sector trained in economics and policy, I also embody an underrepresented voice in our public discourse.  </p>
      <p>
        The above facts do not mean I don’t appreciate the very real concerns of struggling homeowners and drivers. To the contrary, I care deeply about their challenges and I strive to serve these residents just as diligently. It does, however, mean I can add a unique perspective to our council and spark a different kind of conversation from government and politics as usual.
      </p>

      <div className="about-pictures">
        <StaticImage
          src="../images/woman.jpg"
          placeholder="blurred"
          alt="women"
          className="pictureBreak--women"
        />
        <StaticImage
          src="../images/tree.jpg"
          placeholder="blurred"
          alt="Tree"
          className="pictureBreak--tree"
        />
        {/* <figcaption>Charles River Reservation, Cambridge</figcaption> */}
      </div>
      <p className="quote">
        I love my longtime home of Cambridge. I’m running because I believe I can make it better. My mission is to help all in Cambridge have the opportunity to thrive.
      </p>
      <div className="signature">
        <StaticImage
          src="../images/dana_sig.png"
          alt="yellow trees surrounding Charles river"
          placeholder="blurred"
          width={300}
        />
        <br />
        <div className="signature--name">Dana Bullister</div>
      </div>
      <div className="meetDana--link">
        <br />
      </div>
      <h6>Note on Conflicts of Interest</h6>
      <p>
        I have not accepted money, endorsements, aid, or any other form of contribution from the real estate development community. Nor do I have related investments, associations, or even personal ties. I similarly have not accepted contributions from fossil fuel companies, corporate PACs, or any other parties with potential conflicts of interest with the city. My heart and financial interests are with the residents of Cambridge.
      </p>
    </div>
  ),
};
